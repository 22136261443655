import { AfterViewChecked, AfterViewInit, Component, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { CommonService } from 'src/app/services/common/common.service';
import { HomeService } from 'src/app/services/home.service';

import { ILocStrgUser } from 'src/app/model/user';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit, AfterViewChecked {
  title = 'Patient Portal';
  objectLoadedSubscription = new Subscription();

  constructor(
    private tracker: MatomoTracker,
    private commonService: CommonService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private homeService: HomeService,
   
  )
  {
    this.commonService.patternStyleLoading$.subscribe((progress) =>
    {
      if(progress=="triggered")
      {
        this.commonService.setLibraryFiles(this.document);
      }
    })
  }

      ngOnInit() {
    console.log('adding logs to change code iter 2')
   this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.tracker.trackPageView('programName=' + this.commonService.getProgramName());
      }
    });
    if( 'serviceWorker' in navigator ){
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => registration.unregister());
      });
    }
  }

  ngAfterViewInit() {
    if (window.location.href.indexOf('dashboard') > 0) {
      this.objectLoadedSubscription = this.homeService.userObjectLoaded$.subscribe((msg) => {
        if (msg) {
          setTimeout(() => {
            let loader = this.renderer.selectRootElement('.loader');
            this.renderer.setStyle(loader, 'display', 'none');
          }, 0);
        }
      });
    }
    else {
      setTimeout(() => {
        let loader = this.renderer.selectRootElement('.loader');
        this.renderer.setStyle(loader, 'display', 'none');
      }, 2000);

    }

  }


  ngAfterViewChecked(){

  }
}
